const UserTypes = {
  CEO: { display: "CEO", backend: "ceo" },
  SUPER_ADMIN: { display: "Super Admin", backend: "superadmin" },
  ADMIN: { display: "Admin", backend: "admin" },
  STAFF: { display: "Staff", backend: "staff" },
  HR: { display: "HR", backend: "hr" },
  FLOATER: { display: "Floater", backend: "floater" },
};

export const ClinicUserTypes = {
  ADMIN: UserTypes.ADMIN,
  STAFF: UserTypes.STAFF,
};

export const PrivilegedUserTypes = {
  SUPER_ADMIN: UserTypes.SUPER_ADMIN,
  HR: UserTypes.HR,
  FLOATER: UserTypes.FLOATER,
};

export const AttendanceUserTypes = {
  STAFF: UserTypes.STAFF,
  FLOATER: UserTypes.FLOATER,
};

export default UserTypes;
