export const routePermissions = {
  "/ceo-login": ["guest"],
  "/ceo-dashboard": ["ceo"],
  "/clinics": ["superadmin"],
  "/individual-clinic": ["superadmin"],
  "/users": ["superadmin"],
  "/mark-attendance": ["admin"],
  "/attendance": ["staff", "floater", "hr"],
  "/login": ["guest"],
};

export const checkRoutePermission = (pathname, userType) => {
  for (const [route, allowedRoles] of Object.entries(routePermissions)) {
    if (pathname.startsWith(route) && allowedRoles.includes(userType)) {
      return true;
    }
  }
  return false;
};
