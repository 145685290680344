import React, { useState } from "react";
import { Box, Typography, Snackbar } from "@mui/material";
import {
  AppTextfield,
  AppSelectDropdown,
  AppButton,
  ForgotPasswordModal,
} from "../../components/components";
import { UserTypes } from "../../constants/constants";
import { SplitCardLayout } from "../../layout/layout";
import { loginUser } from "../../services/services";
import { useNavigate, useLocation } from "react-router-dom";
import {
  getBackendUserType,
  navigateBasedOnUserType,
  setAuthToken,
  setUserType,
  validatePassword,
} from "../../utils/utils";

function LoginScreen() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [selectedUserType, setSelectedUserType] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [forgotPasswordModalOpen, setForgotPasswordModalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const isCeoLogin = location.pathname === "/ceo-login";

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validatePassword(password)) {
      setError(
        "Password must contain at least one uppercase letter, one lowercase letter, and one special character."
      );
      setSnackbarOpen(true);
      return;
    }

    setLoading(true);
    setError(null);

    // The display value*(selectedUserType) is the value that the user sees, but the backend expects the backend value
    const backendUserType = isCeoLogin
      ? "ceo"
      : getBackendUserType(selectedUserType);

    if (isCeoLogin) {
      setAuthToken("dummy-token-for-ceo");
      setUserType("ceo");
      navigate("/ceo-dashboard");
      return;
    }

    try {
      const response = await loginUser(email, password, backendUserType);
      console.log(response);
      if (response.success) {
        setAuthToken(response.token);
        setUserType(backendUserType);
        navigateBasedOnUserType(navigate, response.data);
      } else {
        throw new Error(response.message || "Failed to Login");
      }
    } catch (error) {
      setError(error.message || "Failed to Login");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleForgotPasswordClick = () => {
    setForgotPasswordModalOpen(true);
  };

  const handleForgotPasswordModalClose = () => {
    setForgotPasswordModalOpen(false);
  };

  return (
    <SplitCardLayout illustration="/assets/images/login-illustration.jpg">
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: isCeoLogin ? "center" : "flex-start",
          "@media (max-width:900px)": {
            marginTop: 5,
          },
        }}
      >
        <img
          src="/assets/logos/logo.png"
          alt="AZZ Medical Associates Logo"
          style={{ maxWidth: "60%", height: "12%" }}
        />

        <Box component="form" noValidate onSubmit={() => {}} sx={{ mt: 1 }}>
          <AppTextfield
            label="Username"
            value={email}
            onChange={(e) => setEmail(e.target.value.trim())}
            required
            autoComplete="email"
            autoFocus
          />
          <AppTextfield
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type="password"
            required
            autoComplete="current-password"
          />
          {!isCeoLogin && (
            <AppSelectDropdown
              value={selectedUserType}
              onChange={(e) => setSelectedUserType(e.target.value)}
              options={Object.values(UserTypes)
                .filter((type) => type.backend !== "ceo")
                .map((type) => type.display)}
              placeholder="Select User Type"
            />
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                cursor: "pointer",
                textDecoration: "none",
                "&:hover": {
                  textDecoration: "underline",
                },
              }}
              onClick={handleForgotPasswordClick}
            >
              Forgot Password?
            </Typography>
          </Box>
          <AppButton
            label="Login"
            loading={loading}
            loadingText="Logging in..."
            type="submit"
            onClick={handleSubmit}
          />
        </Box>
        {error && (
          <Snackbar
            open={snackbarOpen}
            onClose={handleCloseSnackbar}
            message={error}
            autoHideDuration={6000}
          />
        )}
      </Box>
      <ForgotPasswordModal
        open={forgotPasswordModalOpen}
        handleClose={handleForgotPasswordModalClose}
      />
    </SplitCardLayout>
  );
}

export default LoginScreen;
