import React from "react";
import { TextField } from "@mui/material";

function AppTextfield({
  label,
  value,
  onChange,
  type = "text",
  required = false,
  ...rest
}) {
  return (
    <TextField
      variant="outlined"
      margin="normal"
      required={required}
      fullWidth
      label={label}
      value={value}
      onChange={onChange}
      type={type}
      {...rest}
    />
  );
}

export default AppTextfield;
