import React, { useEffect, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { authMiddleware } from "../middleware/authMiddleware";
import { isAuthenticated } from "../utils/helpers/auth-helper";
import { Snackbar, Alert } from "@mui/material";

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const auth = isAuthenticated();
  const {
    isAllowed,
    redirectPath,
    message: redirectMessage,
  } = authMiddleware(location.pathname);

  useEffect(() => {
    if (!auth) {
      navigate("/login", { state: { from: location }, replace: true });
    } else if (!isAllowed) {
      let state = {};
      if (redirectPath === "/attendance") {
        state = JSON.parse(localStorage.getItem("staffState") || "{}");
      } else if (redirectPath === "/mark-attendance") {
        state = JSON.parse(localStorage.getItem("adminState") || "{}");
      }
      navigate(redirectPath, { state, replace: true });
      setMessage(redirectMessage);
      setOpen(true);
    }
  }, [auth, isAllowed, navigate, location, redirectPath, redirectMessage]);

  if (!auth || !isAllowed) {
    return null;
  }

  return (
    <>
      {children}
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert
          onClose={() => setOpen(false)}
          severity="warning"
          sx={{ width: "100%" }}
        >
          {message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default ProtectedRoute;
