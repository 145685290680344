import React, { useState } from "react";
import {
  CssBaseline,
  Box,
  Toolbar,
  Grid2,
  Card,
  CardContent,
  Typography,
} from "@mui/material";
import { CardSkeleton } from "../../../components/components";
import { AppHeader, AppDrawer } from "../../../components/components";

function SuperAdminLayout({ children, cardsData, loading }) {
  const [drawerOpen, setDrawerOpen] = useState(true);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppHeader
        drawerOpen={drawerOpen}
        handleDrawerToggle={handleDrawerToggle}
        showMenuIcon={true}
      />
      <AppDrawer open={drawerOpen} onClose={handleDrawerToggle} />
      <Box
        component="main"
        sx={{
          width: { sm: `calc(100% - 240px)` },
          transition: (theme) =>
            theme.transitions.create(["margin", "width"], {
              easing: theme.transitions.easing.sharp,
              duration: theme.transitions.duration.leavingScreen,
            }),
        }}
      >
        <Toolbar />
        <Box
          sx={{
            width: "100%",
            backgroundColor: "primary.main",
            height: loading || cardsData ? 160 : "auto",
            position: "relative",
          }}
        >
          <Grid2
            container
            spacing={3}
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
              position: "absolute",
              top: 10,
              padding: "3rem",
            }}
          >
            {loading
              ? [...Array(cardsData.length || 3)].map((_, index) => (
                  <CardSkeleton key={index} />
                ))
              : cardsData &&
                cardsData.map((card, index) => (
                  <Card
                    key={index}
                    sx={{
                      width: "20%",
                      display: "flex",
                      alignItems: "center",
                      padding: "1rem",
                      borderRadius: 3,
                    }}
                  >
                    <CardContent sx={{ flexGrow: 1 }}>
                      <Typography variant="h6">{card.title}</Typography>
                      <Typography variant="h4">{card.value}</Typography>
                    </CardContent>
                    <Box sx={{ marginLeft: "auto", marginRight: "12px" }}>
                      {card.icon}
                    </Box>
                  </Card>
                ))}
          </Grid2>
        </Box>
        <Box sx={{ height: "1rem", marginTop: 2 }}></Box>
        <Box sx={{ p: 3, m: 3, borderRadius: 3, boxShadow: 2 }}>{children}</Box>
        <Box sx={{ height: "1rem" }}></Box>
      </Box>
    </Box>
  );
}

export default SuperAdminLayout;
