import React, { useState, useEffect } from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import AppTextfield from "../app-textfield";
import { shouldShowAttendancePin, validatePassword } from "../../utils/utils";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const ModalForm = ({
  open,
  handleClose,
  mode,
  type,
  onSubmit,
  selectedItem,
  loading,
}) => {
  const isClinic = type === "clinic";
  const showAttendancePin = shouldShowAttendancePin(type);

  const [formData, setFormData] = useState({
    name: "",
    address: "",
    email: "",
    username: "",
    password: "",
    attendancePin: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    address: "",
    email: "",
    username: "",
    password: "",
    attendancePin: "",
  });

  useEffect(() => {
    if (selectedItem && (mode === "edit" || mode === "view")) {
      setFormData({
        name: selectedItem.name || "",
        address: selectedItem.address || "",
        email: selectedItem.email || "",
        username: selectedItem.username || "",
        attendancePin: selectedItem.attendance_pin || "",
      });
    } else {
      setFormData({
        name: "",
        address: "",
        email: "",
        username: "",
        password: "",
        attendancePin: "",
      });
    }
  }, [selectedItem, mode]);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (formData.password && !validatePassword(formData.password)) {
      setErrors({
        ...errors,
        password:
          "Password must contain at least one uppercase letter, one lowercase letter, and one special character.",
      });
      return;
    }

    onSubmit(formData);
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {mode === "add"
            ? `Add ${type.charAt(0).toUpperCase() + type.slice(1)}`
            : mode === "edit"
            ? `Edit ${type.charAt(0).toUpperCase() + type.slice(1)}`
            : `View ${type.charAt(0).toUpperCase() + type.slice(1)}`}
        </Typography>
        <Box
          component="form"
          noValidate
          sx={{
            mt: 1,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
          onSubmit={handleSubmit}
        >
          <AppTextfield
            required
            id="name"
            label="Name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            error={!!errors.name}
            helperText={errors.name}
            disabled={mode === "view"}
          />
          {isClinic && (
            <AppTextfield
              required
              id="address"
              label="Address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              error={!!errors.address}
              helperText={errors.address}
              disabled={mode === "view"}
            />
          )}
          {!isClinic && (
            <>
              <AppTextfield
                required
                id="email"
                label="Email Address"
                name="email"
                value={formData.email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
                disabled={mode === "edit" || mode === "view"}
              />
              <AppTextfield
                required
                id="username"
                label="Username"
                name="username"
                value={formData.username}
                onChange={handleChange}
                error={!!errors.username}
                helperText={errors.username}
                disabled={mode === "edit" || mode === "view"}
              />
              {mode === "add" && (
                <AppTextfield
                  required
                  id="password"
                  label="Password"
                  name="password"
                  type="password"
                  value={formData.password}
                  onChange={handleChange}
                  error={!!errors.password}
                  helperText={errors.password}
                  disabled={mode === "edit" || mode === "view"}
                />
              )}
              {showAttendancePin &&
                (mode === "add" || formData.attendancePin !== "") && (
                  <AppTextfield
                    required
                    type="number"
                    id="attendancePin"
                    label="Attendance PIN"
                    name="attendancePin"
                    value={formData.attendancePin}
                    onChange={(e) => {
                      const value = e.target.value
                        .replace(/\D/g, "")
                        .slice(0, 5);
                      setFormData({ ...formData, attendancePin: value });
                    }}
                    error={formData.attendancePin.length !== 5}
                    helperText={
                      formData.attendancePin.length !== 5
                        ? "PIN must be 5 digits"
                        : ""
                    }
                    disabled={mode === "view"}
                    inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
                  />
                )}
            </>
          )}
          <Box sx={{ display: "flex", justifyContent: "flex-end", gap: 2 }}>
            <Button onClick={handleClose} variant="outlined" color="error">
              Cancel
            </Button>
            {mode !== "view" && (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={
                  loading ||
                  (showAttendancePin && formData.attendancePin.length !== 5)
                }
              >
                {loading
                  ? mode === "add"
                    ? "Adding..."
                    : "Saving..."
                  : mode === "add"
                  ? "Add"
                  : "Save"}
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default ModalForm;
