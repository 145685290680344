export const captureAndMarkAttendance = async ({
  videoRef,
  canvasRef,
  userId,
  action,
  updatedClinicId,
  username,
  setDialogMessage,
  setDialogOpen,
  setOptions,
  setUsername,
  setAttendancePin,
  setLoading,
  markAttendance,
  closeDialog,
}) => {
  const canvas = canvasRef.current;
  const context = canvas.getContext("2d");

  console.log(
    "Video dimensions:",
    videoRef.current.videoWidth,
    videoRef.current.videoHeight
  );
  console.log("Canvas dimensions:", canvas.width, canvas.height);

  context.drawImage(videoRef.current, 0, 0, canvas.width, canvas.height);
  canvas.toBlob(async (blob) => {
    if (!blob) {
      console.error("Failed to create blob from canvas");
      setLoading(false);
      return;
    }

    // Create a File object from the blob
    const file = new File([blob], `attendance_${userId}_${Date.now()}.png`, {
      type: "image/png",
    });

    var formData = new FormData();
    formData.append("user_id", userId.toString());
    formData.append("attendance_action", action);
    formData.append("image_file", file, file.name);
    if (updatedClinicId && updatedClinicId !== "null") {
      formData.append("clinic_id", updatedClinicId.toString());
    }
    formData.append("timezone", "America/New_York");

    // Log FormData contents
    console.log("FormData contents:");
    for (let [key, value] of formData.entries()) {
      console.log(
        `${key}:`,
        value instanceof File ? `File: ${value.name}` : value
      );
    }

    try {
      const response = await markAttendance(formData);
      if (response.success) {
        const formattedAction = action.replace(/_/g, " ");
        const message = `${username}, your ${formattedAction} has been marked successfully.`;
        setDialogMessage(message);
        setDialogOpen(true);

        setTimeout(() => {
          closeDialog();
        }, 3000);
      } else {
        setDialogMessage(response.message || "Failed to mark attendance");
        setDialogOpen(true);
      }
    } catch (error) {
      console.error("Failed to mark attendance:", error);
      setDialogMessage("Failed to mark attendance: " + error.message);
      setDialogOpen(true);
    } finally {
      setLoading(false);
    }
  }, "image/png");
};
