import React from "react";
import { Select, MenuItem, FormControl } from "@mui/material";

function AppSelectDropdown({
  label,
  value,
  onChange,
  options = [],
  placeholder = "Select",
  ...rest
}) {
  return (
    <FormControl fullWidth variant="outlined" sx={{ my: 1 }}>
      <Select value={value} onChange={onChange} displayEmpty {...rest}>
        <MenuItem value="" disabled>
          {placeholder}
        </MenuItem>
        {options.map((option) => (
          <MenuItem key={option.id || option} value={option.id || option}>
            {option.name || option}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default AppSelectDropdown;
