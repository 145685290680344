import React from "react";
import { Box, Container, Grid, Paper } from "@mui/material";
import { MainLayout } from "./layout";

export function SplitCardLayout({ children, illustration }) {
  return (
    <MainLayout>
      <Container
        component="main"
        maxWidth="lg"
        sx={{
          "@media (max-width:900px)": {
            display: "flex",
            justifyContent: "center",
            marginTop: 5,
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            minHeight: "100vh",
            "@media (max-width:900px)": {
              width: "70%",
              maxWidth: "none",
            },
          }}
        >
          <Paper
            elevation={3}
            sx={{
              borderRadius: 4,
              overflow: "hidden",
              display: "flex",
              width: "100%",
              maxWidth: 1000,
            }}
          >
            <Grid container>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    backgroundImage: `url(${illustration})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    height: "100%",
                    minHeight: 500,
                    "@media (max-width:900px)": {
                      marginTop: 5,
                      height: "100%",
                      minHeight: 200,
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box
                  sx={{
                    p: 4,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  {children}
                </Box>
              </Grid>
            </Grid>
          </Paper>
        </Box>
      </Container>
    </MainLayout>
  );
}
