import React, { useState } from "react";
import {
  Box,
  Modal,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Snackbar,
} from "@mui/material";
import AppTextfield from "../app-textfield";
import AppButton from "../app-button";
import { forgotPassword, confirmForgotPassword } from "../../services/services";
import { validatePassword } from "../../utils/utils";

const ForgotPasswordModal = ({ open, handleClose }) => {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const steps = ["Forgot Password", "Reset Password"];

  const handleEmailChange = (e) => setEmail(e.target.value.trim());
  const handleCodeChange = (e) => setCode(e.target.value.trim());
  const handleNewPasswordChange = (e) => setNewPassword(e.target.value);

  const handleSubmitEmail = async () => {
    if (!email) {
      setErrorMessage("Email is required.");
      return;
    }

    setLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    try {
      const response = await forgotPassword(email);
      if (response.success) {
        setSuccessMessage("Password reset email sent successfully.");
      } else {
        throw new Error(
          response.error || "Failed to send password reset email"
        );
      }
    } catch (error) {
      setErrorMessage(error.message || "Error sending password reset email.");
    } finally {
      setLoading(false);
    }
  };

  const handleConfirmPasswordReset = async () => {
    if (!code || !newPassword) {
      setErrorMessage("Code and new password are required.");
      return;
    }

    if (!validatePassword(newPassword)) {
      setErrorMessage(
        "Password must contain at least one uppercase letter, one lowercase letter, and one special character."
      );
      return;
    }

    setLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    try {
      const response = await confirmForgotPassword(email, code, newPassword);
      console.log(response);
      if (response.success) {
        setSuccessMessage("Password has been reset successfully.");
        setSnackbarOpen(true);
        handleClose();
      } else {
        throw new Error(response.error || "Failed to reset password");
      }
    } catch (error) {
      setErrorMessage(error.message || "Error resetting password.");
    } finally {
      setLoading(false);
    }
  };

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleModalClose = () => {
    setEmail("");
    setCode("");
    setNewPassword("");
    setErrorMessage("");
    setSuccessMessage("");
    setActiveStep(0);
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleModalClose}
      aria-labelledby="forgot-password-modal"
      aria-describedby="forgot-password-description"
    >
      <div>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            border: "2px solid #000",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === 0 ? (
            <>
              <Typography
                id="forgot-password-modal"
                variant="h6"
                component="h2"
              >
                Forgot Password
              </Typography>
              <Typography id="forgot-password-description" sx={{ mt: 2 }}>
                Please enter an email address associated with your account so
                that we can send you a confirmation code.
              </Typography>
              <AppTextfield
                required
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                variant="outlined"
                autoFocus
                color="primary"
                value={email}
                sx={{ mt: 2 }}
                onChange={handleEmailChange}
              />
              {errorMessage && (
                <Typography color="error">{errorMessage}</Typography>
              )}
              {successMessage && (
                <Typography color="success">{successMessage}</Typography>
              )}
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <AppButton
                  label={successMessage ? "Send Again" : "Send Email"}
                  loading={loading}
                  loadingText="Sending..."
                  onClick={handleSubmitEmail}
                />
                <Box sx={{ width: 20 }} />
                <AppButton
                  label="Next"
                  disabled={!successMessage}
                  onClick={handleNext}
                />
              </Box>
            </>
          ) : (
            <>
              <Typography
                id="confirm-forgot-password-modal"
                variant="h6"
                component="h2"
              >
                Confirm Forgot Password
              </Typography>
              <AppTextfield
                required
                id="code"
                label="Code"
                name="code"
                value={code}
                sx={{ mt: 2 }}
                onChange={handleCodeChange}
              />
              <AppTextfield
                required
                id="new-password"
                label="New Password"
                name="newPassword"
                type="password"
                value={newPassword}
                sx={{ mt: 2 }}
                onChange={handleNewPasswordChange}
              />
              <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>
                Your password must contain at least one uppercase letter, one
                number, and one special character.
              </Typography>
              {errorMessage && (
                <Typography color="error">{errorMessage}</Typography>
              )}
              <AppButton
                label="Reset Password"
                loading={loading}
                loadingText="Resetting..."
                sx={{ mt: 2 }}
                onClick={handleConfirmPasswordReset}
              />
            </>
          )}
        </Box>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
          message={successMessage || errorMessage}
        />
      </div>
    </Modal>
  );
};

export default ForgotPasswordModal;
