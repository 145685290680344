export const setAuthToken = (token) => {
  localStorage.setItem("authToken", token);
};

export const getAuthToken = () => {
  return localStorage.getItem("authToken");
};

export const removeAuthToken = () => {
  localStorage.removeItem("authToken");
};

export const isAuthenticated = () => {
  return !!getAuthToken();
};

export const setUserType = (userType) => {
  localStorage.setItem("userType", userType);
};

export const getUserType = () => {
  return localStorage.getItem("userType");
};

export const removeUserType = () => {
  localStorage.removeItem("userType");
};

export const handleLogout = (navigate) => {
  removeAuthToken();
  removeUserType();
  localStorage.removeItem("adminState");
  localStorage.removeItem("staffState");
  navigate("/login");
};
