import React from "react";
import { Box } from "@mui/material";
import { AppHeader } from "../components/components";
import { useLocation } from "react-router-dom";

export function MainLayout({ children }) {
  const location = useLocation();
  const isLoginPage =
    location.pathname === "/login" || location.pathname === "/ceo-login";

  return (
    <Box
      sx={{
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        overflow: "hidden",
        backgroundImage: "url(/assets/images/background.svg)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
      }}
    >
      {!isLoginPage && <AppHeader showLogoutButton={true} />}
      <Box
        component="img"
        src="/assets/images/circle.svg"
        alt="Decorative circle"
        sx={{
          position: "fixed",
          bottom: 0,
          right: 0,
          width: "30%",
          height: "auto",
          zIndex: 0,
        }}
      />
      <Box
        sx={{
          flexGrow: 1,
          position: "relative",
          zIndex: 1,
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          paddingTop: 0,
        }}
      >
        {children}
      </Box>
    </Box>
  );
}
