import {
  CLINIC_COUNTS_ENDPOINT,
  CLINICS_ENDPOINT,
  CLINIC_ENDPOINT,
  USER_ENDPOINT,
  USERS_ENDPOINT,
} from "../api-urls";

export const getClinicCounts = async () => {
  try {
    const response = await fetch(CLINIC_COUNTS_ENDPOINT, {
      method: "GET",
    });

    if (!response.ok) {
      throw new Error(
        `Fetching Clinic Counts failed: ${response.status} ${response.statusText}`
      );
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Failed to fetch clinic counts:", error);
    throw error;
  }
};

export const createClinic = async (name, address) => {
  const requestBody = {
    name,
    address,
  };

  try {
    const response = await fetch(CLINIC_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    if (!response.ok) {
      throw new Error(
        `Creating Clinic failed: ${response.status} ${response.statusText}`
      );
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Failed to create clinic:", error);
    throw error;
  }
};

export const updateClinic = async (id, name, address) => {
  try {
    const response = await fetch(`${CLINIC_ENDPOINT}/?id=${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ name, address }),
    });

    if (!response.ok) {
      throw new Error(
        `Updating Clinic failed: ${response.status} ${response.statusText}`
      );
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Failed to update clinic:", error);
    throw error;
  }
};

export const deleteClinic = async (id) => {
  try {
    const response = await fetch(`${CLINIC_ENDPOINT}?id=${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(
        `Deleting Clinic failed: ${response.status} ${response.statusText}`
      );
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Failed to delete clinic:", error);
    throw error;
  }
};

export const getClinic = async (id) => {
  try {
    const response = await fetch(`${CLINIC_ENDPOINT}?id=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(
        `Fetching Clinic failed: ${response.status} ${response.statusText}`
      );
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Failed to fetch clinic:", error);
    throw error;
  }
};

export const getAllClinics = async () => {
  try {
    const response = await fetch(`${CLINICS_ENDPOINT}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(
        `Fetching All Clinics failed: ${response.status} ${response.statusText}`
      );
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Failed to fetch all clinics:", error);
    throw error;
  }
};

export const getUsers = async (clinicId, role, userId) => {
  try {
    let url = `${USERS_ENDPOINT}?role=${role}`;
    if (clinicId !== "all" && clinicId !== "privileged-users") {
      url += `&clinic_id=${clinicId}`;
    }
    if (userId) {
      url += `&id=${userId}`;
    }
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Failed to fetch users:", error);
    throw error;
  }
};

export const createUser = async (userData) => {
  try {
    console.log(userData);
    const response = await fetch(USER_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Failed to create user:", error);
    throw error;
  }
};

export const updateUser = async (id, userData) => {
  try {
    const response = await fetch(`${USER_ENDPOINT}?id=${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Failed to update user:", error);
    throw error;
  }
};

export const deleteUser = async (id) => {
  try {
    const response = await fetch(`${USER_ENDPOINT}?id=${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (!response.ok) {
      throw new Error(
        `Deleting User failed: ${response.status} ${response.statusText}`
      );
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Failed to delete user:", error);
    throw error;
  }
};
