// src/theme.js
import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#2E62C8",
    },
    white: "#FFFFFF",
    success: {
      main: "#008000",
    },
  },
});

export default theme;
