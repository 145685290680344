import { UserTypes } from "../../constants/constants";

export const getBackendUserType = (displayValue) => {
  const userTypeEntry = Object.values(UserTypes).find(
    (type) => type.display === displayValue
  );
  return userTypeEntry ? userTypeEntry.backend : displayValue;
};

export const getDisplayUserType = (backendValue) => {
  const userTypeEntry = Object.values(UserTypes).find(
    (type) => type.backend === backendValue
  );
  return userTypeEntry ? userTypeEntry.display : backendValue;
};

export const shouldShowAttendancePin = (role) => {
  return role === "staff" || role === "floater";
};