import React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField } from '@mui/material';

const DatePickerField = ({ label, value, onChange, sx }) => {
  return (
    <DatePicker
      label={label}
      value={value}
      onChange={onChange}
      renderInput={(params) => (
        <TextField {...params} sx={{ ...sx, minWidth: 220 }} />
      )}
    />
  );
};

export default DatePickerField;
