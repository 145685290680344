import React from 'react';
import { Box, Typography } from '@mui/material';
import { GraphCardLayout } from '../../../../layout/layout';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from 'recharts';

function StaffAttendance() {
  // Dummy data for the past week
  const dummyData = [
    {
      name: 'Monday',
      'Clinic A': 15,
      'Clinic B': 12,
      'Clinic C': 8,
      'Clinic D': 10,
    },
    {
      name: 'Tuesday',
      'Clinic A': 14,
      'Clinic B': 13,
      'Clinic C': 9,
      'Clinic D': 11,
    },
    {
      name: 'Wednesday',
      'Clinic A': 16,
      'Clinic B': 11,
      'Clinic C': 7,
      'Clinic D': 9,
    },
    {
      name: 'Thursday',
      'Clinic A': 13,
      'Clinic B': 14,
      'Clinic C': 10,
      'Clinic D': 12,
    },
    {
      name: 'Friday',
      'Clinic A': 15,
      'Clinic B': 12,
      'Clinic C': 8,
      'Clinic D': 10,
    },
  ];

  const clinicColors = {
    'Clinic A': '#8884d8',
    'Clinic B': '#82ca9d',
    'Clinic C': '#ffc658',
    'Clinic D': '#ff7f50',
  };

  // Custom tooltip component
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Box
          sx={{
            bgcolor: 'background.paper',
            p: 1,
            border: '1px solid #ccc',
            borderRadius: 1,
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {label}
          </Typography>
          {payload.map((entry, index) => (
            <Typography
              key={index}
              variant="body2"
              sx={{ color: entry.color }}
            >
              {`${entry.name}: ${entry.value}`}
            </Typography>
          ))}
        </Box>
      );
    }
    return null;
  };

  return (
    <GraphCardLayout title="Staff Attendance">
      <Box sx={{ width: '100%', height: '100%' }}>
        <ResponsiveContainer width="100%" height={250}>
          <BarChart
            data={dummyData}
            margin={{ top: 10, right: 30, left: 0, bottom: 20 }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              dataKey="name"
              tick={{ fontSize: 10 }}
              interval={0}
              angle={-35}
              textAnchor="end"
              height={60}
            />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Legend
              layout="vertical"
              align="right"
              verticalAlign="middle"
              wrapperStyle={{
                paddingLeft: '10px',
                fontSize: '12px',
              }}
            />
            {Object.keys(clinicColors).map((clinic) => (
              <Bar
                key={clinic}
                dataKey={clinic}
                stackId="a"
                fill={clinicColors[clinic]}
              />
            ))}
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </GraphCardLayout>
  );
}

export default StaffAttendance; 