import React from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  IconButton,
  Box,
  Divider,
} from "@mui/material";
import {
  ChevronLeft as ChevronLeftIcon,
  Dashboard as DashboardIcon,
  People as PeopleIcon,
  ExitToApp as ExitToAppIcon,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { handleLogout } from "../utils/utils";
import { PrivilegedUserTypes } from "../constants/constants";

const AppDrawer = ({ open, onClose }) => {
  const navigate = useNavigate();

  return (
    <Drawer
      variant="persistent"
      anchor="left"
      open={open}
      sx={{
        width: 240,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: 240,
          boxSizing: "border-box",
          bgcolor: "primary.main",
          color: "primary.contrastText",
        },
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", padding: 2 }}>
        <Typography
          variant="h6"
          sx={{ flexGrow: 1, color: "primary.contrastText" }}
        >
          Super Admin
        </Typography>
        <IconButton onClick={onClose} sx={{ color: "primary.contrastText" }}>
          <ChevronLeftIcon />
        </IconButton>
      </Box>
      <Divider sx={{ bgcolor: "primary.light" }} />
      <List>
        <ListItem button onClick={() => navigate("/clinics")}>
          <ListItemIcon sx={{ color: "primary.contrastText" }}>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        <ListItem
          button
          onClick={() =>
            navigate(
              `/users/privileged-users/${
                Object.values(PrivilegedUserTypes)[0].backend
              }`
            )
          }
        >
          <ListItemIcon sx={{ color: "primary.contrastText" }}>
            <PeopleIcon />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItem>
      </List>
      <Divider sx={{ bgcolor: "primary.light" }} />
      <List>
        <ListItem button onClick={() => handleLogout(navigate)}>
          <ListItemIcon sx={{ color: "primary.contrastText" }}>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </Drawer>
  );
};

export default AppDrawer;
