import { UserTypes } from "../../constants/constants";

export const navigateBasedOnUserType = (navigate, userData) => {
  console.log(userData);
  const userType = userData.role;
  switch (userType) {
    case UserTypes.CEO.backend:
      navigate("/ceo-dashboard");
      break;
    case UserTypes.SUPER_ADMIN.backend:
      navigate("/clinics");
      break;
    case UserTypes.ADMIN.backend:
      const adminState = {
        clinicId: userData.clinic_id,
      };
      localStorage.setItem("adminState", JSON.stringify(adminState));
      navigate("/mark-attendance", { state: adminState });
      break;
    case UserTypes.STAFF.backend:
    case UserTypes.FLOATER.backend:
      const staffState = {
        userId: userData.id,
        role: userData.role,
        clinicId: userData.clinic_id,
      };
      localStorage.setItem("staffState", JSON.stringify(staffState));
      navigate("/attendance", { state: staffState });
      break;
    case UserTypes.HR.backend:
      navigate("/attendance");
      break;
    default:
      navigate("/login");
  }
};
