import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { AppTextfield } from "../../components/components";
import { changeAttendancePin } from "../../services/authentication/staff-service";

const AttendancePinModal = ({ open, onClose, userId, onSuccess, onError }) => {
  const [newPin, setNewPin] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await changeAttendancePin(userId, newPin);
      console.log(response);
      if (response.success) {
        onSuccess(response.message || "Attendance pin changed successfully");
        setNewPin("");
        onClose();
      } else {
        onError(response.message || "Failed to change attendance pin");
      }
    } catch (error) {
      onError(error.message || "Failed to change attendance pin");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Change Attendance Pin</DialogTitle>
      <DialogContent>
        <AppTextfield
          type="number"
          autoFocus
          margin="dense"
          label="New Attendance Pin"
          fullWidth
          value={newPin}
          onChange={(e) => {
            const value = e.target.value.replace(/\D/g, "").slice(0, 5);
            setNewPin(value);
          }}
          inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
          error={newPin.length !== 5}
          helperText={newPin.length !== 5 ? "PIN must be 5 digits" : ""}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={loading}>
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          disabled={loading || newPin.length !== 5}
        >
          {loading ? "Changing..." : "Change Pin"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AttendancePinModal;
