import {
  GET_ATTENDANCE_ENDPOINT,
  CHECK_AVAILABLE_OPTIONS_ENDPOINT,
  MARK_ATTENDANCE_ENDPOINT,
} from "../api-urls";

export const getAttendance = async (params) => {
  const queryParams = Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
  try {
    const response = await fetch(`${GET_ATTENDANCE_ENDPOINT}?${queryParams}`, {
      method: "GET",
    });
    if (!response.ok) {
      throw new Error(
        `Fetching Attendance Data failed: ${response.status} ${response.statusText}`
      );
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Failed to fetch attendance data:", error);
    throw error;
  }
};

export const checkAvailableOptions = async (params) => {
  const queryParams = Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join("&");
  console.log(queryParams);
  try {
    const response = await fetch(
      `${CHECK_AVAILABLE_OPTIONS_ENDPOINT}?${queryParams}`,
      {
        method: "GET",
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Failed to check available options:", error);
    throw error;
  }
};

export const markAttendance = async (formData) => {
  console.log("Sending FormData in markAttendance:");
  for (let [key, value] of formData.entries()) {
    console.log(key, value instanceof File ? `File: ${value.name}` : value);
  }

  try {
    const response = await fetch(MARK_ATTENDANCE_ENDPOINT, {
      method: "POST",
      body: formData,

      // Don't set Content-Type header, let the browser set it with the boundary
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(
        `Marking attendance failed: ${response.status} ${response.statusText}\n${errorText}`
      );
    }
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Failed to mark attendance:", error);
    throw error;
  }
};
