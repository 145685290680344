import { CHANGE_ATTENDANCE_PIN_ENDPOINT } from "../api-urls";

export const changeAttendancePin = async (userId, newPin) => {
  try {
    const response = await fetch(CHANGE_ATTENDANCE_PIN_ENDPOINT, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: userId,
        attendance_pin: newPin,
      }),
    });

    return await response.json();
  } catch (error) {
    console.error("Error changing attendance pin:", error);
    throw error;
  }
};
