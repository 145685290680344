// Base URLS
export const AUTHENTICATION_URL =
  "https://ntmaiu2vga.execute-api.us-east-1.amazonaws.com/dev";
export const ATTENDANCE_URL =
  "https://fh0zxplexf.execute-api.us-east-1.amazonaws.com/dev";

// Authentication related endpoints
export const LOGIN_ENDPOINT = `${AUTHENTICATION_URL}/login`;
export const FORGOT_PASSWORD_ENDPOINT = `${AUTHENTICATION_URL}/forgot_password`;
export const CONFIRM_FORGOT_PASSWORD_ENDPOINT = `${AUTHENTICATION_URL}/confirm_forgot_password`;

export const CLINIC_COUNTS_ENDPOINT = `${AUTHENTICATION_URL}/clinic_counts`;
export const CLINICS_ENDPOINT = `${AUTHENTICATION_URL}/clinics`;
export const CLINIC_ENDPOINT = `${AUTHENTICATION_URL}/clinic`;
export const USER_ENDPOINT = `${AUTHENTICATION_URL}/user`;
export const USERS_ENDPOINT = `${AUTHENTICATION_URL}/users`;
export const CHANGE_ATTENDANCE_PIN_ENDPOINT = `${AUTHENTICATION_URL}/change_attendnace_pin`;

// Attendance related endpoints
export const GET_ATTENDANCE_ENDPOINT = `${ATTENDANCE_URL}/get_attendance`;
export const CHECK_AVAILABLE_OPTIONS_ENDPOINT = `${ATTENDANCE_URL}/check`;
export const MARK_ATTENDANCE_ENDPOINT = `${ATTENDANCE_URL}/mark_attendance`;

