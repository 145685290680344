import React from "react";
import { Box } from "@mui/material";
import { GraphCardLayout } from "../../../../layout/layout";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function StaffHours() {
  // Dummy data for staff hours
  const data = {
    labels: ["Clinic A", "Clinic B", "Clinic C", "Clinic D", "Clinic E"],
    datasets: [
      {
        label: "Average Hours per Day",
        data: [7.5, 8.2, 6.8, 7.9, 8.5],
        backgroundColor: [
          "#32CD32", // Maximum (highlighted)
          "#4682B4",
          "#4682B4",
          "#4682B4",
          "#FF6347", // Minimum (highlighted)
        ],
        borderRadius: 20,
        barThickness: 20,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.parsed.y} hours`;
          },
        },
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return `${value}h`;
          },
          stepSize: 2,
        },
      },
    },
  };

  return (
    <GraphCardLayout title="Staff Hours">
      <Box sx={{ width: "100%", height: "100%", p: 2 }}>
        <Bar data={data} options={options} height={200} />
      </Box>
    </GraphCardLayout>
  );
}

export default StaffHours;
