import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Select,
  MenuItem,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import { ArrowBack, Add as AddIcon } from "@mui/icons-material";
import SuperAdminLayout from "./sections/super-admin-layout";
import {
  AppTable,
  ModalForm,
  DeleteModalForm,
  TableSkeleton,
} from "../../components/components";
import {
  getUsers,
  createUser,
  updateUser,
  deleteUser,
} from "../../services/services";
import {
  ClinicUserTypes,
  PrivilegedUserTypes,
} from "../../constants/constants";
import {
  getBackendUserType,
  getDisplayUserType,
} from "../../utils/helpers/user-helper";

function UsersScreen() {
  const [users, setUsers] = useState([]);
  const [selectedRole, setSelectedRole] = useState("");
  const [modalMode, setModalMode] = useState("");
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formLoading, setFormLoading] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const navigate = useNavigate();
  const { clinicId, role: initialRole } = useParams();

  const isPrivilegedUsersPage = clinicId === "privileged-users";
  const userTypes = isPrivilegedUsersPage
    ? PrivilegedUserTypes
    : ClinicUserTypes;

  useEffect(() => {
    setSelectedRole(initialRole);
    fetchUsers(initialRole);
  }, [clinicId, initialRole]);

  const fetchUsers = async (role) => {
    try {
      setLoading(true);
      const response = await getUsers(clinicId, role);
      if (response.success) {
        setUsers(response.data);
      } else {
        setError("Failed to fetch users");
      }
    } catch (error) {
      setError("An error occurred while fetching users");
    } finally {
      setLoading(false);
    }
  };

  const handleRoleChange = (event) => {
    const newRole = event.target.value;
    setSelectedRole(newRole);
    navigate(`/users/${clinicId}/${newRole}`);
    fetchUsers(newRole);
  };

  const handleOpenModal = (mode, user = null) => {
    setSelectedUser(user);
    setModalMode(mode);
  };

  const handleCloseModal = () => {
    setModalMode("");
    setSelectedUser(null);
  };

  const handleOpenDeleteModal = (user) => {
    setSelectedUser(user);
    setModalMode("delete");
  };

  const handleCloseDeleteModal = () => {
    setModalMode("");
    setSelectedUser(null);
  };

  const handleRowClick = (user) => {
    handleOpenModal("view", user);
  };

  const handleBack = () => {
    if (isPrivilegedUsersPage) {
      navigate("/clinics");
    } else {
      navigate(`/individual-clinic/${clinicId}`);
    }
  };

  const handleSubmit = async (formData) => {
    setFormLoading(true);
    try {
      if (modalMode === "add") {
        const response = await createUser({
          ...formData,
          attendance_pin: formData.attendancePin,
          role: getBackendUserType(selectedRole),
          clinic_id: isPrivilegedUsersPage ? null : clinicId,
        });
        if (!response.success) {
          throw new Error(response.message || "Failed to create user");
        }
        setSnackbar({
          open: true,
          message: "User created successfully",
          severity: "success",
        });
      } else if (modalMode === "edit") {
        const response = await updateUser(selectedUser.id, {
          name: formData.name,
          role: getBackendUserType(selectedRole),
          attendance_pin: formData.attendancePin,
        });
        if (!response.success) {
          throw new Error(response.message || "Failed to update user");
        }
        setSnackbar({
          open: true,
          message: "User updated successfully",
          severity: "success",
        });
      }
      fetchUsers(selectedRole);
      handleCloseModal();
    } catch (error) {
      setSnackbar({ open: true, message: error.message, severity: "error" });
    } finally {
      setFormLoading(false);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      const response = await deleteUser(selectedUser.id);
      if (!response.success) {
        throw new Error(response.message || "Failed to delete user");
      }
      setSnackbar({
        open: true,
        message: "User deleted successfully",
        severity: "success",
      });
      fetchUsers(selectedRole);
      handleCloseDeleteModal();
    } catch (error) {
      setSnackbar({ open: true, message: error.message, severity: "error" });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const columns = [
    { id: "name", label: "Name" },
    { id: "username", label: "Username" },
    { id: "email", label: "Email" },
    { id: "action", label: "Action", align: "right" },
  ];

  return (
    <SuperAdminLayout>
      <Box sx={{ marginBottom: "1rem" }}>
        <Button
          onClick={handleBack}
          startIcon={<ArrowBack />}
          style={{ textTransform: "none" }}
        >
          Back
        </Button>
      </Box>
      {isPrivilegedUsersPage && (
        <Typography variant="h6" sx={{ marginBottom: "1rem" }}>
          Privileged Users
        </Typography>
      )}

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <Select value={selectedRole} onChange={handleRoleChange}>
          {Object.entries(userTypes).map(([key, value]) => (
            <MenuItem key={key} value={value.backend}>
              {value.display}
            </MenuItem>
          ))}
        </Select>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => handleOpenModal("add")}
        >
          New {getDisplayUserType(selectedRole)}
        </Button>
      </Box>

      {loading ? (
        <TableSkeleton rows={5} columns={4} />
      ) : (
        <AppTable
          ariaLabel="users table"
          columns={columns}
          rows={users}
          onEdit={(user) => handleOpenModal("edit", user)}
          onDelete={handleOpenDeleteModal}
          onClick={handleRowClick}
        />
      )}

      <ModalForm
        open={
          modalMode === "add" || modalMode === "edit" || modalMode === "view"
        }
        handleClose={handleCloseModal}
        mode={modalMode}
        type={selectedRole}
        onSubmit={handleSubmit}
        selectedItem={selectedUser}
        loading={formLoading}
      />

      <DeleteModalForm
        open={modalMode === "delete"}
        handleClose={handleCloseDeleteModal}
        onConfirm={handleConfirmDelete}
        message="Are you sure you want to delete this user?"
      />

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </SuperAdminLayout>
  );
}

export default UsersScreen;
