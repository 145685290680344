import { checkRoutePermission } from "../constants/routePermissions";
import { getUserType } from "../utils/helpers/auth-helper";

export const authMiddleware = (pathname) => {
  const userType = getUserType();

  if (!checkRoutePermission(pathname, userType)) {
    let redirectPath;
    let message = "You do not have permission to access this page.";

    switch (userType) {
      case "superadmin":
        redirectPath = "/clinics";
        break;
      case "admin":
        redirectPath = "/mark-attendance";
        break;
      case "staff":
      case "floater":
      case "hr":
        redirectPath = "/attendance";
        break;
      default:
        redirectPath = "/login";
        message = "Please log in to access this page.";
    }

    console.log("authMiddleware - Redirecting to:", redirectPath);
    return { isAllowed: false, redirectPath, message };
  }
  return { isAllowed: true };
};
