import React from "react";
import { AppBar, Toolbar, Box, IconButton, Button } from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { handleLogout } from "../utils/utils";

const AppHeader = ({
  drawerOpen,
  handleDrawerToggle,
  showMenuIcon = false,
  showLogoutButton = false,
  isStaffFloater,
  onChangeAttendancePin,
}) => {
  const navigate = useNavigate();

  return (
    <AppBar
      position="fixed"
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: "white",
        boxShadow: 1,
        width: drawerOpen ? `calc(100% - 240px)` : "100%",
        transition: (theme) =>
          theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      <Toolbar sx={{ justifyContent: "space-between" }}>
        {showMenuIcon && (
          <IconButton
            color="primary"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, ...(drawerOpen && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Box
          sx={{
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            margin: "1rem",
          }}
        >
          <img
            src="/assets/logos/logo.png"
            alt="AZZ Medical Associates Logo"
            style={{ maxWidth: "20%", height: "20%" }}
          />
        </Box>
        <Box>
          {isStaffFloater && (
            <Button
              color="primary"
              onClick={onChangeAttendancePin}
              sx={{ mr: 2 }}
            >
              Change Attendance Pin
            </Button>
          )}
          {showLogoutButton && (
            <Button color="primary" onClick={() => handleLogout(navigate)}>
              Logout
            </Button>
          )}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default AppHeader;
