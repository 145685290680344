import React from "react";
import { Button, CircularProgress } from "@mui/material";

function AppButton({
  label,
  loading = false,
  loadingText = "Loading",
  ...rest
}) {
  return (
    <Button
      fullWidth
      variant="contained"
      sx={{
        mt: 3,
        mb: 2,
        bgcolor: "primary.main",
        textTransform: "none",
      }}
      disabled={loading}
      {...rest}
    >
      {loading ? (
        <>
          <CircularProgress size={24} color="inherit" sx={{ mr: 1 }} />
          {loadingText}
        </>
      ) : (
        label
      )}
    </Button>
  );
}

export default AppButton;
