import React from 'react';
import { Card, CardContent, Typography, Box } from '@mui/material';

export function GraphCardLayout({ title, children, ref }) {
  return (
    <Card
      sx={{
        p: 3,
        m: 1,
        borderRadius: 3,
        boxShadow: 2,
        height: 300,
      }}
      ref={ref}
    >
      <CardContent>
        <Typography 
          variant="h6" 
          sx={{ 
            mb: 2,
            fontWeight: 'bold',
            color: 'primary.main'
          }}
        >
          {title}
        </Typography>
        <Box
          sx={{
            height: 'calc(100% - 40px)', // Subtract title height
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          {children}
        </Box>
      </CardContent>
    </Card>
  );
} 