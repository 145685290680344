import {
  LOGIN_ENDPOINT,
  FORGOT_PASSWORD_ENDPOINT,
  CONFIRM_FORGOT_PASSWORD_ENDPOINT,
} from "../api-urls";

export const loginUser = async (username_or_email, password, role) => {
  const requestBody = {
    username_or_email,
    password,
    role,
  };

  try {
    const response = await fetch(LOGIN_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Login failed:", error);
    throw error;
  }
};

export const forgotPassword = async (email) => {
  const requestBody = {
    email,
  };

  try {
    const response = await fetch(FORGOT_PASSWORD_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Forgot Password failed:", error);
    throw error;
  }
};

export const confirmForgotPassword = async (
  email,
  confirmation_code,
  new_password
) => {
  const requestBody = {
    email,
    confirmation_code,
    new_password,
  };

  try {
    const response = await fetch(CONFIRM_FORGOT_PASSWORD_ENDPOINT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Password Reset failed:", error);
    throw error;
  }
};
