import React, { useState } from "react";
import { CssBaseline, Box, Snackbar, Alert } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AppHeader } from "../../../components/components";
import AttendancePinModal from "../../../components/modals/attendance-pin-modal";

function AttendanceLayout({ children, userId, isStaffFloater }) {
  console.log("AttendanceLayout rendering", { userId, isStaffFloater }); // Add this line
  const navigate = useNavigate();
  const [isAttendancePinModalOpen, setIsAttendancePinModalOpen] =
    useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });

  const handleChangeAttendancePin = () => {
    setIsAttendancePinModalOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleSuccess = (message) => {
    setSnackbar({ open: true, message, severity: "success" });
  };

  const handleError = (message) => {
    setSnackbar({ open: true, message, severity: "error" });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <CssBaseline />
      <AppHeader
        showLogoutButton={true}
        isStaffFloater={isStaffFloater}
        onChangeAttendancePin={handleChangeAttendancePin}
      />
      <Box component="main" sx={{ flexGrow: 1, pt: 8, mt: 3 }}>
        <Box sx={{ p: 3, m: 3, borderRadius: 3, boxShadow: 2 }}>{children}</Box>
      </Box>
      <AttendancePinModal
        open={isAttendancePinModalOpen}
        onClose={() => setIsAttendancePinModalOpen(false)}
        userId={userId}
        onSuccess={handleSuccess}
        onError={handleError}
      />
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ width: "100%" }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default AttendanceLayout;
