import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Typography, Snackbar, Skeleton } from "@mui/material";
import {
  ArrowBack,
  Edit as EditIcon,
  Delete as DeleteIcon,
  Person as PersonIcon,
  SupervisorAccount as SupervisorAccountIcon,
} from "@mui/icons-material";
import SuperAdminLayout from "./sections/super-admin-layout";
import {
  AppTable,
  ModalForm,
  DeleteModalForm,
  TableSkeleton,
} from "../../components/components";
import {
  getClinicCounts,
  updateClinic,
  deleteClinic,
  getClinic,
} from "../../services/services";
import { getBackendUserType } from "../../utils/utils";

function IndividualClinicScreen() {
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedClinic, setSelectedClinic] = useState(null);
  const [clinicData, setClinicData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formLoading, setFormLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const navigate = useNavigate();
  const { clinicId } = useParams();

  useEffect(() => {
    fetchClinicData();
  }, [clinicId]);

  const fetchClinicData = async () => {
    try {
      setLoading(true);
      const response = await getClinicCounts();
      if (response.success) {
        const clinic = response.data.find(
          (c) => c.clinic_id === parseInt(clinicId)
        );
        if (clinic) {
          setClinicData(clinic);
        } else {
          setError("Clinic not found");
        }
      } else {
        setError("Failed to fetch clinic data");
      }
    } catch (error) {
      setError("An error occurred while fetching clinic data");
    } finally {
      setLoading(false);
    }
  };

  const handleOpenEditModal = async () => {
    try {
      const response = await getClinic(clinicId);
      if (response.success) {
        setSelectedClinic(response.data);
        setOpenEditModal(true);
      } else {
        setError("Failed to fetch clinic details");
      }
    } catch (error) {
      setError("An error occurred while fetching clinic details");
    }
  };

  const handleCloseEditModal = () => {
    setOpenEditModal(false);
    setSelectedClinic(null);
  };

  const handleOpenDeleteModal = () => {
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
  };

  const handleBack = () => {
    navigate("/clinics");
  };

  const handleRowClick = (row) => {
    navigate(`/users/${clinicId}/${getBackendUserType(row.role)}`);
  };

  const handleSubmit = async (formData) => {
    setFormLoading(true);
    try {
      await updateClinic(clinicId, formData.name, formData.address);
      setSnackbarMessage("Clinic updated successfully");
      setSnackbarOpen(true);
      handleCloseEditModal();
      fetchClinicData();
    } catch (error) {
      setError("Failed to update clinic");
      setSnackbarOpen(true);
    } finally {
      setFormLoading(false);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteClinic(clinicId);
      setSnackbarMessage("Clinic deleted successfully");
      setSnackbarOpen(true);
      handleCloseDeleteModal();
      navigate("/clinics");
    } catch (error) {
      setError("Failed to delete clinic");
      setSnackbarOpen(true);
    }
  };

  const cardsData = [
    {
      title: "Total Admins",
      value: clinicData?.role_counts.admin || 0,
      icon: <PersonIcon fontSize="large" color="primary" />,
    },
    {
      title: "Total Staff",
      value: clinicData?.role_counts.staff || 0,
      icon: <SupervisorAccountIcon fontSize="large" color="primary" />,
    },
  ];

  const columns = [
    { id: "role", label: "Roles" },
    { id: "members", label: "Members", align: "right" },
    { id: "action", label: "Action", align: "right" },
  ];

  const rows = clinicData
    ? [
        { id: 1, role: "Admin", members: clinicData.role_counts.admin },
        { id: 2, role: "Staff", members: clinicData.role_counts.staff },
      ]
    : [];

  return (
    <SuperAdminLayout cardsData={cardsData} loading={loading}>
      <Box sx={{ marginBottom: "1rem" }}>
        <Button
          onClick={handleBack}
          startIcon={<ArrowBack />}
          style={{ textTransform: "none" }}
        >
          Back
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <Typography variant="h6">
          {loading ? <Skeleton width={150} /> : clinicData?.clinic_name}
        </Typography>
        <Box>
          <Button
            variant="contained"
            color="primary"
            size="small"
            style={{ height: 30 }}
            startIcon={<EditIcon />}
            onClick={handleOpenEditModal}
          >
            Edit
          </Button>
          <Button
            variant="contained"
            color="error"
            size="small"
            style={{ height: 30, marginLeft: 10 }}
            startIcon={<DeleteIcon />}
            onClick={handleOpenDeleteModal}
          >
            Delete
          </Button>
        </Box>
      </Box>

      {loading ? (
        <TableSkeleton rows={5} columns={3} />
      ) : (
        <AppTable
          ariaLabel="roles table"
          columns={columns}
          rows={rows}
          onClick={handleRowClick}
        />
      )}

      <ModalForm
        open={openEditModal}
        handleClose={handleCloseEditModal}
        mode="edit"
        type="clinic"
        onSubmit={handleSubmit}
        selectedItem={selectedClinic}
        loading={formLoading}
      />

      <DeleteModalForm
        open={openDeleteModal}
        handleClose={handleCloseDeleteModal}
        onConfirm={handleConfirmDelete}
        message="Are you sure you want to delete this clinic?"
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage || error}
      />
    </SuperAdminLayout>
  );
}

export default IndividualClinicScreen;
