import React, { useState, useEffect } from "react";
import { Box, Grid, Button } from "@mui/material";
import { Download as DownloadIcon } from "@mui/icons-material";
import { MainLayout } from "../../layout/layout";
import { AppSelectDropdown } from "../../components/components";
import { getAllClinics } from "../../services/services";
import { downloadReport } from "../../utils/utils";
import {
  TopFiveStaff,
  BottomFiveStaff,
  StaffAttendance,
  StaffHours,
} from "./sections/graphs/graphs";

function CeoDashboard() {
  const [clinics, setClinics] = useState([]);
  const [selectedClinic, setSelectedClinic] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchClinics();
  }, []);

  const fetchClinics = async () => {
    try {
      const response = await getAllClinics();
      if (response.success) {
        const clinicOptions = [
          { id: "all", name: "All Clinics" },
          ...response.data.map((clinic) => ({
            id: clinic.id,
            name: clinic.name,
          })),
        ];
        setClinics(clinicOptions);
      }
    } catch (error) {
      console.error("Failed to fetch clinics:", error);
    }
  };

  const handleDownload = async () => {
    try {
      setLoading(true);
      await downloadReport({
        title: "CEO Dashboard Report",
        subtitle:
          selectedClinic === "all"
            ? "All Clinics"
            : clinics.find((c) => c.id === selectedClinic)?.name ||
              "Selected Clinic",
        docName: "CEO_Dashboard_Report.pdf",
      });
    } catch (error) {
      console.error("Failed to download report:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <MainLayout>
      <Box sx={{ p: 3, mt: 10 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mb: 4,
          }}
        >
          <Box sx={{ width: "300px" }}>
            <AppSelectDropdown
              options={clinics}
              value={selectedClinic}
              onChange={(e) => setSelectedClinic(e.target.value)}
              placeholder="Select Clinic"
            />
          </Box>
          <Button
            variant="contained"
            color="primary"
            startIcon={<DownloadIcon />}
            onClick={handleDownload}
            disabled={loading}
          >
            {loading ? "Downloading..." : "Download Report"}
          </Button>
        </Box>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TopFiveStaff />
          </Grid>
          <Grid item xs={12} md={6}>
            <BottomFiveStaff />
          </Grid>
          <Grid item xs={12} md={6}>
            <StaffAttendance />
          </Grid>
          <Grid item xs={12} md={6}>
            <StaffHours />
          </Grid>
        </Grid>
      </Box>
    </MainLayout>
  );
}

export default CeoDashboard;
