import React from "react";
import { Box } from "@mui/material";
import { GraphCardLayout } from "../../../../layout/layout";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function TopFiveStaff() {
  const data = {
    labels: [
      "John Smith",
      "Sarah Lee",
      "Mike Johnson",
      "Emma Davis",
      "Tom Wilson",
    ],
    datasets: [
      {
        label: "Hours Worked",
        data: [45, 42, 40, 38, 37],
        backgroundColor: [
          "#32CD32",
          "#4682B4",
          "#4682B4",
          "#4682B4",
          "#4682B4",
        ],
        borderRadius: 20,
        barThickness: 20,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.parsed.y} hours`;
          },
        },
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return `${value}h`;
          },
          stepSize: 5,
        },
      },
    },
  };

  return (
    <GraphCardLayout title="Top Five Staff">
      <Box sx={{ width: "100%", height: "100%", p: 2 }}>
        <Bar data={data} options={options} height={200} />
      </Box>
    </GraphCardLayout>
  );
}

export default TopFiveStaff;
