import React from "react";
import { Card, CardContent, Box, Skeleton } from "@mui/material";

function CardSkeleton() {
  return (
    <Card
      sx={{
        width: "20%",
        padding: "1rem",
        borderRadius: 3,
        display: "flex",
        alignItems: "center",
      }}
    >
      <CardContent sx={{ flexGrow: 1 }}>
        <Skeleton variant="text" width="60%" height={30} />
        <Skeleton variant="text" width="80%" height={40} />
      </CardContent>
      <Box sx={{ marginLeft: "auto", marginRight: "12px" }}>
        <Skeleton variant="circular" width={40} height={40} />
      </Box>
    </Card>
  );
}

export default CardSkeleton;
