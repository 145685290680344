import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box, Button, Select, MenuItem, Grid } from "@mui/material";
import { Download as DownloadIcon } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import AttendanceLayout from "./sections/attendance-layout";
import {
  AppTable,
  TableSkeleton,
  DatePickerField,
} from "../../components/components";
import { AttendanceUserTypes, DateRanges } from "../../constants/constants";
import {
  getAllClinics,
  getUsers,
  getAttendance,
} from "../../services/services";
import { getUserType, downloadReport } from "../../utils/utils";

function AttendanceScreen() {
  console.log("AttendanceScreen rendering");
  const location = useLocation();
  const storedState = JSON.parse(localStorage.getItem("staffState") || "{}");
  const { userId, role, clinicId } = location.state || storedState;
  const userType = role ?? getUserType();
  const isStaffFloater = Object.values(AttendanceUserTypes).some(
    (type) => type.backend === userType
  );
  console.log("isStaffFloater:", isStaffFloater, "userType:", userType); // Add this line

  const defaultStaffType = Object.values(AttendanceUserTypes)[0].backend;
  const [staffType, setStaffType] = useState(defaultStaffType);
  const [clinics, setClinics] = useState([]);
  const [selectedClinic, setSelectedClinic] = useState("all");
  const [staff, setStaff] = useState([]);
  const [selectedStaff, setSelectedStaff] = useState("all");
  const [dateRange, setDateRange] = useState(1);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [attendanceData, setAttendanceData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isFloaterStaffType, setIsFloaterStaffType] = useState(false);

  useEffect(() => {
    if (!isStaffFloater) {
      fetchClinics();
      fetchStaff();
    }
  }, []);

  useEffect(() => {
    setSelectedClinic("all");
  }, [staffType]);

  useEffect(() => {
    const isFloater = staffType === AttendanceUserTypes.FLOATER.backend;
    setIsFloaterStaffType(isFloater);
    if (isFloater) {
      setSelectedClinic("all");
    }
    fetchStaff();
  }, [staffType, selectedClinic]);

  useEffect(() => {
    fetchAttendance();
  }, [staffType, selectedClinic, selectedStaff, dateRange, startDate, endDate]);

  const fetchClinics = async () => {
    try {
      const response = await getAllClinics();
      setClinics([{ id: "all", name: "All Clinics" }, ...response.data]);
    } catch (error) {
      console.error("Failed to fetch clinics:", error);
    }
  };

  const fetchStaff = async () => {
    console.log(isFloaterStaffType, isStaffFloater, selectedClinic);
    if (
      staffType === AttendanceUserTypes.FLOATER.backend ||
      (staffType === AttendanceUserTypes.STAFF.backend &&
        selectedClinic === "all")
    ) {
      // Fetch all staff for floater type or when all clinics are selected
      try {
        const response = await getUsers("all", staffType);
        setStaff([{ id: "all", name: "All Staff" }, ...response.data]);
      } catch (error) {
        console.error("Failed to fetch staff:", error);
      }
    } else if (
      staffType === AttendanceUserTypes.STAFF.backend &&
      selectedClinic !== "all"
    ) {
      // Fetch staff for the selected clinic
      try {
        const response = await getUsers(selectedClinic, staffType);
        setStaff([{ id: "all", name: "All Staff" }, ...response.data]);
      } catch (error) {
        console.error("Failed to fetch staff:", error);
      }
    }
  };

  const fetchAttendance = async () => {
    setLoading(true);
    try {
      const params = {
        staff_type: !isStaffFloater ? staffType : userType,
        days: dateRange,
        timezone: "America/New_York",
      };

      if (!isStaffFloater) {
        if (selectedClinic !== "all" && !isFloaterStaffType) {
          params.clinic_id = selectedClinic;
        }

        if (selectedStaff !== "all") {
          params.user_id = selectedStaff;
        }
      } else {
        if (userType !== AttendanceUserTypes.FLOATER.backend) {
          params.clinic_id = clinicId;
        }
        params.user_id = userId;
      }

      if (startDate) {
        params.start_date = dayjs(startDate).format("YYYYMMDD");
      }

      if (endDate) {
        params.end_date = dayjs(endDate).format("YYYYMMDD");
      }

      console.log(params);
      const response = await getAttendance(params);
      console.log(response);
      setAttendanceData(response.data.attendance_records);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch attendance data:", error);
    }
  };

  const handleDownload = async () => {
    try {
      const tableColumns = columns.map((column) => column.label);

      const tableRows = attendanceData.map((row) => {
        return columns.map((column) => row[column.id] || "N/A");
      });

      console.log("Prepared table rows:", tableRows);

      await downloadReport({
        title: "Attendance Report",
        subtitle: `${
          isFloaterStaffType
            ? "All Clinics"
            : selectedClinic === "all"
            ? "All Clinics"
            : clinics.find((c) => c.id === selectedClinic)?.name
        }`,
        table: true,
        tableColumns: tableColumns,
        tableRows: tableRows,
        docName: "Staff_Attendance_Report.pdf",
      });
    } catch (error) {
      console.error("Failed to download report:", error);
    }
  };

  const columns = [
    { id: "name", label: "Name" },
    ...(isFloaterStaffType
      ? []
      : [{ id: "clinic_name", label: "Clinic Name" }]),
    { id: "date", label: "Date" },
    { id: "check_in", label: "Check In" },
    { id: "check_in_image_url", label: "Check In Image" },
    { id: "check_out", label: "Check Out" },
    { id: "check_out_image_url", label: "Check Out Image" },
    { id: "break_in", label: "Break Start" },
    { id: "break_out", label: "Break End" },
    { id: "total_hours", label: "Total Hours" },
  ];

  return (
    <AttendanceLayout userId={userId} isStaffFloater={isStaffFloater}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid container spacing={2} alignItems="center">
          {!isStaffFloater && (
            <Grid item xs={12} sm={6} md={2}>
              <Select
                value={staffType}
                onChange={(e) => setStaffType(e.target.value)}
                fullWidth
              >
                {Object.entries(AttendanceUserTypes).map(([key, value]) => (
                  <MenuItem key={key} value={value.backend}>
                    {value.display}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
          {!isStaffFloater && !isFloaterStaffType && (
            <Grid item xs={12} sm={6} md={2}>
              <Select
                value={selectedClinic}
                onChange={(e) => setSelectedClinic(e.target.value)}
                fullWidth
              >
                {clinics.map((clinic) => (
                  <MenuItem key={clinic.id} value={clinic.id}>
                    {clinic.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
          {!isStaffFloater && (
            <Grid item xs={12} sm={6} md={2}>
              <Select
                value={selectedStaff}
                onChange={(e) => setSelectedStaff(e.target.value)}
                fullWidth
              >
                {staff.map((s) => (
                  <MenuItem key={s.id} value={s.id}>
                    {s.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          )}
          <Grid item xs={12} sm={6} md={2}>
            <Select
              value={dateRange}
              onChange={(e) => setDateRange(e.target.value)}
              fullWidth
            >
              {DateRanges.map((range) => (
                <MenuItem key={range.value} value={range.value}>
                  {range.display}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <DatePickerField
              label="Start Date"
              value={startDate}
              onChange={(date) => setStartDate(date)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <DatePickerField
              label="End Date"
              value={endDate}
              onChange={(date) => setEndDate(date)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              startIcon={<DownloadIcon />}
              onClick={handleDownload}
              disabled={loading || attendanceData.length === 0}
            >
              {loading ? "Loading..." : "Download Report"}
            </Button>
          </Grid>
        </Grid>
      </LocalizationProvider>
      <Box sx={{ mt: 4 }}>
        {loading ? (
          <TableSkeleton rows={5} columns={9} />
        ) : (
          <AppTable
            ariaLabel="attendance table"
            columns={columns}
            rows={attendanceData}
          />
        )}
      </Box>
    </AttendanceLayout>
  );
}

export default AttendanceScreen;
