import React from "react";
import { Modal, Box } from "@mui/material";

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80%',
  maxWidth: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const ImageModal = ({ open, onClose, imageUrl }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="image-modal-title"
      aria-describedby="image-modal-description"
    >
      <Box sx={style}>
        <img src={imageUrl} alt="Modal View" style={{ width: '100%', height: 'auto' }} />
      </Box>
    </Modal>
  );
};

export default ImageModal;
