import React, { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Box,
  Dialog,
  DialogContent,
  IconButton,
  Typography,
  Button,
  Snackbar,
  Alert,
  Card,
  CardContent,
} from "@mui/material";
import {
  CheckCircle as CheckCircleIcon,
  Close as CloseIcon,
} from "@mui/icons-material";
import { MainLayout, SplitCardLayout } from "../../layout/layout";
import { checkAvailableOptions, markAttendance } from "../../services/services";
import { AppButton, AppTextfield } from "../../components/components";
import { captureAndMarkAttendance } from "../../utils/utils";

function MarkAttendance() {
  const location = useLocation();
  const storedState = JSON.parse(localStorage.getItem("adminState") || "{}");
  const { clinicId } = location.state || storedState;
  const [userId, setUserId] = useState(null);
  const [updatedClinicId, setUpdatedClinicId] = useState(null);
  const [username, setUsername] = useState("");
  const [attendancePin, setAttendancePin] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [selectedAction, setSelectedAction] = useState("");
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("info");
  const [continueClicked, setContinueClicked] = useState(false);
  const [readOnlyMap, setReadOnlyMap] = useState({
    username: true,
    attendancePin: true,
  });

  useEffect(() => {
    let stream;

    const getCameraFeed = async () => {
      try {
        stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      } catch (error) {
        console.error("Failed to access camera:", error);
      }
    };

    if (options.length > 0) {
      getCameraFeed();
    }

    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }
    };
  }, [options]);

  const handleCheckAttendance = async () => {
    setLoading(true);
    setContinueClicked(true);
    try {
      const params = {
        username,
        attendance_pin: attendancePin,
        clinic_id: clinicId,
        timezone: "America/New_York",
      };
      const response = await checkAvailableOptions(params);
      console.log("API Response:", response); // Add this line for debugging

      if (response.success) {
        setOptions(response.data.options);
        setUserId(response.data.user_id);
        setUpdatedClinicId(response.data.clinic_id);
        if (response.data.options.length === 0) {
          setSnackbarMessage("Your attendance is already marked for today.");
          setSnackbarSeverity("info");
          setSnackbarOpen(true);
        }
      } else {
        throw new Error(
          response.message || "Failed to check attendance options"
        );
      }
    } catch (error) {
      setSnackbarMessage(error.message || "Failed to check attendance options");
      setSnackbarSeverity("error");
      setSnackbarOpen(true);
    } finally {
      setLoading(false);
    }
  };

  const closeDialog = () => {
    setDialogOpen(false);
    setOptions([]);
    setUsername("");
    setAttendancePin("");
  };

  const handleMarkAttendance = async (action) => {
    setSelectedAction(action);
    setLoading(true);
    await captureAndMarkAttendance({
      videoRef,
      canvasRef,
      userId,
      action,
      updatedClinicId,
      username,
      setDialogMessage,
      setDialogOpen,
      setOptions,
      setUsername,
      setAttendancePin,
      setLoading,
      markAttendance,
      closeDialog,
    });
  };

  const buttonStyle = {
    borderRadius: "20px",
    minWidth: "auto",
    padding: "8px 16px",
    width: "auto",
  };

  const renderButtons = () => {
    if (options.includes("check_in")) {
      return (
        <AppButton
          label="Check In"
          onClick={() => handleMarkAttendance("check_in")}
          loading={loading && selectedAction === "check_in"}
          sx={buttonStyle}
        />
      );
    }
    if (options.includes("break_in")) {
      return (
        <>
          <AppButton
            label="Break Start"
            onClick={() => handleMarkAttendance("break_in")}
            loading={loading && selectedAction === "break_in"}
            sx={buttonStyle}
          />
          <AppButton
            label="Check Out"
            onClick={() => handleMarkAttendance("check_out")}
            loading={loading && selectedAction === "check_out"}
            sx={buttonStyle}
          />
        </>
      );
    }
    if (options.includes("break_out")) {
      return (
        <AppButton
          label="Break End"
          onClick={() => handleMarkAttendance("break_out")}
          loading={loading && selectedAction === "break_out"}
          sx={buttonStyle}
        />
      );
    }
    if (options.includes("check_out")) {
      return (
        <AppButton
          label="Check Out"
          onClick={() => handleMarkAttendance("check_out")}
          loading={loading && selectedAction === "check_out"}
          sx={buttonStyle}
        />
      );
    }
    return null;
  };

  return (
    <MainLayout>
      {options.length === 0 ? (
        <SplitCardLayout illustration="/assets/images/login-illustration.jpg">
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "90%",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src="/assets/logos/logo.png"
              alt="AZZ Medical Associates Logo"
              style={{ maxWidth: "100%", height: "12%", marginBottom: 30 }}
            />
            <AppTextfield
              label="Username"
              value={username}
              onChange={(e) => setUsername(e.target.value.trim())}
              sx={{
                borderRadius: "20px",
                "& .MuiOutlinedInput-root": { borderRadius: "32px" },
              }}
              onFocus={() =>
                setReadOnlyMap((prev) => ({ ...prev, username: false }))
              }
              onBlur={() =>
                setReadOnlyMap((prev) => ({ ...prev, username: true }))
              }
              inputProps={{
                autocomplete: "new-password",
                form: {
                  autocomplete: "off",
                },
              }}
            />
            <AppTextfield
              label="Attendance Pin"
              value={attendancePin}
              type="password"
              onChange={(e) => setAttendancePin(e.target.value.trim())}
              sx={{
                borderRadius: "20px",
                "& .MuiOutlinedInput-root": { borderRadius: "32px" },
                marginBottom: 3,
              }}
              onFocus={() =>
                setReadOnlyMap((prev) => ({ ...prev, attendancePin: false }))
              }
              onBlur={() =>
                setReadOnlyMap((prev) => ({ ...prev, attendancePin: true }))
              }
              inputProps={{
                autocomplete: "new-password",
                form: {
                  autocomplete: "off",
                },
              }}
            />
            <AppButton
              label="Continue"
              onClick={handleCheckAttendance}
              loading={loading}
              loadingText="Processing..."
              sx={buttonStyle}
            />
          </Box>
        </SplitCardLayout>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minHeight: "calc(100vh - 64px)",
            "@media (max-width:1200px)": {
              marginBottom: 10,
            },
          }}
        >
          <Card
            sx={{
              width: "45%",
              maxWidth: "750px",
              marginTop: 15,
              "@media (max-width:1200px)": {
                width: "76%",
                maxWidth: "none",
              },
            }}
          >
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 2,
                  alignItems: "center",
                }}
              >
                <video
                  ref={videoRef}
                  autoPlay
                  style={{
                    width: "100%",
                    aspectRatio: "4/3",
                    "@media (max-width:1200px)": {
                      aspectRatio: "1/1",
                    },
                  }}
                />
                <canvas
                  ref={canvasRef}
                  style={{ display: "none" }}
                  width="640"
                  height="480"
                />
                {renderButtons()}
              </Box>
            </CardContent>
          </Card>
        </Box>
      )}
      <Dialog open={dialogOpen} onClose={closeDialog}>
        <DialogContent sx={{ textAlign: "center", position: "relative" }}>
          <IconButton
            aria-label="close"
            onClick={closeDialog}
            sx={{ position: "absolute", right: 8, top: 8 }}
          >
            <CloseIcon />
          </IconButton>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "green",
              borderRadius: "50%",
              width: 50,
              height: 50,
              margin: "0 auto",
              color: "white",
            }}
          >
            <CheckCircleIcon />
          </Box>
          <Typography variant="h5" sx={{ mt: 2 }}>
            Attendance Marked
          </Typography>
          <Typography variant="body1" sx={{ mt: 1 }}>
            {username}, your {selectedAction.replace(/_/g, " ")} has been marked
            successfully.
          </Typography>
          <Button
            variant="contained"
            color="primary"
            onClick={closeDialog}
            sx={{ mt: 5 }}
          >
            OK
          </Button>
        </DialogContent>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={() => setSnackbarOpen(false)}
          severity={snackbarSeverity}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </MainLayout>
  );
}

export default MarkAttendance;
