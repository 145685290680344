import React from "react";
import { Box } from "@mui/material";
import { GraphCardLayout } from "../../../../layout/layout";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function BottomFiveStaff() {
  const data = {
    labels: [
      "Alex Brown",
      "Lisa Chen",
      "James White",
      "Anna Park",
      "Chris Lee",
    ],
    datasets: [
      {
        label: "Hours Worked",
        data: [20, 22, 24, 25, 26],
        backgroundColor: [
          "#FF6347", // Lowest (highlighted)
          "#4682B4",
          "#4682B4",
          "#4682B4",
          "#4682B4",
        ],
        borderRadius: 20,
        barThickness: 20,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: function (context) {
            return `${context.parsed.y} hours`;
          },
        },
      },
      datalabels: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        ticks: {
          callback: function (value) {
            return `${value}h`;
          },
          stepSize: 5,
        },
      },
    },
  };

  return (
    <GraphCardLayout title="Bottom Five Staff">
      <Box sx={{ width: "100%", height: "100%", p: 2 }}>
        <Bar data={data} options={options} height={200} />
      </Box>
    </GraphCardLayout>
  );
}

export default BottomFiveStaff;
