import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography, Snackbar } from "@mui/material";
import {
  Add as AddIcon,
  LocalHospital as LocalHospitalIcon,
  Person as PersonIcon,
  SupervisorAccount as SupervisorAccountIcon,
} from "@mui/icons-material";
import SuperAdminLayout from "./sections/super-admin-layout";
import {
  AppTable,
  ModalForm,
  DeleteModalForm,
} from "../../components/components";
import {
  getClinicCounts,
  createClinic,
  updateClinic,
  deleteClinic,
  getClinic,
} from "../../services/services";
import { TableSkeleton } from "../../components/components";

function ClinicsScreen() {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [modalMode, setModalMode] = useState("add");
  const [selectedClinic, setSelectedClinic] = useState(null);
  const [clinicData, setClinicData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formLoading, setFormLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    fetchClinicData();
  }, []);

  const fetchClinicData = async () => {
    try {
      setLoading(true);
      const response = await getClinicCounts();
      if (response.success) {
        setClinicData(response.data);
      } else {
        setError("Failed to fetch clinic data");
      }
    } catch (error) {
      setError("An error occurred while fetching clinic data");
    } finally {
      setLoading(false);
    }
  };

  const handleOpenAddModal = async (mode, clinic = null) => {
    setModalMode(mode);
    if (mode === "edit" && clinic) {
      try {
        const response = await getClinic(clinic.id);
        if (response.success) {
          setSelectedClinic(response.data);
        } else {
          setError("Failed to fetch clinic details");
          return;
        }
      } catch (error) {
        setError("An error occurred while fetching clinic details");
        return;
      }
    } else {
      setSelectedClinic(clinic);
    }
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
    setSelectedClinic(null);
  };

  const handleOpenDeleteModal = (clinic) => {
    setSelectedClinic(clinic);
    setOpenDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal(false);
    setSelectedClinic(null);
  };

  const handleRowClick = (clinic) => {
    navigate(`/individual-clinic/${clinic.id}`);
  };

  const handleSubmit = async (formData) => {
    setFormLoading(true);
    try {
      if (modalMode === "add") {
        await createClinic(formData.name, formData.address);
        setSnackbarMessage("Clinic created successfully");
      } else {
        await updateClinic(selectedClinic.id, formData.name, formData.address);
        setSnackbarMessage("Clinic updated successfully");
      }
      setSnackbarOpen(true);
      handleCloseAddModal();
      fetchClinicData();
    } catch (error) {
      setError(`Failed to ${modalMode} clinic`);
      setSnackbarOpen(true);
    } finally {
      setFormLoading(false);
    }
  };

  const handleConfirmDelete = async () => {
    try {
      await deleteClinic(selectedClinic.id);
      setSnackbarMessage("Clinic deleted successfully");
      setSnackbarOpen(true);
      handleCloseDeleteModal();
      fetchClinicData();
    } catch (error) {
      setError("Failed to delete clinic");
      setSnackbarOpen(true);
    }
  };

  const totalClinics = clinicData.length;
  const totalAdmins = clinicData.reduce(
    (sum, clinic) => sum + clinic.role_counts.admin,
    0
  );
  const totalStaff = clinicData.reduce(
    (sum, clinic) => sum + clinic.role_counts.staff,
    0
  );

  const cardsData = [
    {
      title: "Total Clinics",
      value: totalClinics,
      icon: <LocalHospitalIcon fontSize="large" color="primary" />,
    },
    {
      title: "Total Admins",
      value: totalAdmins,
      icon: <PersonIcon fontSize="large" color="primary" />,
    },
    {
      title: "Total Staff",
      value: totalStaff,
      icon: <SupervisorAccountIcon fontSize="large" color="primary" />,
    },
  ];

  const columns = [
    { id: "clinic_name", label: "Clinic Name" },
    { id: "admin_count", label: "Admins", align: "right" },
    { id: "staff_count", label: "Staff", align: "right" },
    { id: "action", label: "Action", align: "right" },
  ];

  const rows = clinicData.map((clinic) => ({
    id: clinic.clinic_id,
    clinic_name: clinic.clinic_name,
    admin_count: clinic.role_counts.admin,
    staff_count: clinic.role_counts.staff,
  }));

  return (
    <SuperAdminLayout cardsData={cardsData} loading={loading}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "1rem",
        }}
      >
        <Typography variant="h6">Total Clinics</Typography>
        <Button
          variant="contained"
          color="primary"
          size="large"
          style={{ height: 40 }}
          startIcon={<AddIcon />}
          onClick={() => handleOpenAddModal("add")}
        >
          New Clinic
        </Button>
      </Box>

      {loading ? (
        <TableSkeleton rows={5} columns={4} />
      ) : (
        <AppTable
          ariaLabel="clinic table"
          columns={columns}
          rows={rows}
          onClick={handleRowClick}
          onDelete={handleOpenDeleteModal}
          onEdit={(clinic) => handleOpenAddModal("edit", clinic)}
        />
      )}

      <ModalForm
        open={openAddModal}
        handleClose={handleCloseAddModal}
        mode={modalMode}
        type="clinic"
        onSubmit={handleSubmit}
        selectedItem={selectedClinic}
        loading={formLoading}
      />

      <DeleteModalForm
        open={openDeleteModal}
        handleClose={handleCloseDeleteModal}
        onConfirm={handleConfirmDelete}
        message="Are you sure you want to delete this clinic?"
      />

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage || error}
      />
    </SuperAdminLayout>
  );
}

export default ClinicsScreen;
