import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./constants/theme";
import {
  LoginScreen,
  ClinicsScreen,
  IndividualClinicScreen,
  UsersScreen,
  AttendanceScreen,
  MarkAttendanceScreen,
  CeoDashboard,
} from "./pages/pages";
import ProtectedRoute from "./components/protected-route";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/login" element={<LoginScreen />} />
          <Route path="/ceo-login" element={<LoginScreen />} />
          <Route
            path="/ceo-dashboard"
            element={
              <ProtectedRoute>
                <CeoDashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path="/clinics"
            element={
              <ProtectedRoute>
                <ClinicsScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/individual-clinic/:clinicId"
            element={
              <ProtectedRoute>
                <IndividualClinicScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/users/:clinicId/:role"
            element={
              <ProtectedRoute>
                <UsersScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/attendance"
            element={
              <ProtectedRoute>
                <AttendanceScreen />
              </ProtectedRoute>
            }
          />
          <Route
            path="/mark-attendance"
            element={
              <ProtectedRoute>
                <MarkAttendanceScreen />
              </ProtectedRoute>
            }
          />
          <Route path="*" element={<Navigate to="/login" replace />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
